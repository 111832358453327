import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    containers: null,
    selectedContainer: null,
    containerAccessTypes: null,
    selectedContainerAccessType: null,
    listOfUsers: null,
    usersInContainer: null,
    selectedUsers: null,
    listOfLevels: null,
    levelsInContainer: null,
    selectedLevels: null,
    listOfResources: null,
    resourcesInContainer: null,
    selectedResources: null,
    listOfRoles: null,
    rolesInContainer: null,
    selectedRoles: null,

    listOfReceptionDesks: null,
    receptionDesksInContainer: null,
    selectedReceptionDesks: null
  },
  getters: {},
  mutations: {
    setContainers(state, payload) {
      state.containers = payload;
    },
    setSelectedContainer(state, payload) {
      state.selectedContainer = payload;
    },
    setContainerAccessTypes(state, payload) {
      state.containerAccessTypes = payload;
    },
    setSelectedContainerAccessType(state, payload) {
      state.selectedContainerAccessType = payload;
    },
    setUsersList(state, payload) {
      state.listOfUsers = payload;
    },
    setUsersInContainer(state, payload) {
      state.usersInContainer = payload;
    },
    setSelectedUsers(state, payload) {
      state.selectedUsers = payload;
    },
    setSelectedUser(state, payload) {
      if (state.selectedUsers && state.selectedUsers.length) {
        const index = state.selectedUsers.findIndex(
          item => item.id === payload.id
        );
        if (index !== -1) {
          state.selectedUsers.splice(index, 1);
        } else {
          state.selectedUsers.push(payload);
        }
      } else {
        state.selectedUsers = [payload];
      }
    },
    setLevelsList(state, payload) {
      state.listOfLevels = payload;
    },
    setLevelsInContainer(state, payload) {
      state.levelsInContainer = payload;
    },
    setSelectedLevels(state, payload) {
      state.selectedLevels = payload;
    },
    setSelectedLevel(state, payload) {
      if (state.selectedLevels && state.selectedLevels.length) {
        const index = state.selectedLevels.findIndex(
          item => item.id === payload.id
        );
        if (index !== -1) {
          state.selectedLevels.splice(index, 1);
        } else {
          state.selectedLevels.push(payload);
        }
      } else {
        state.selectedLevels = [payload];
      }
    },
    setResourcesList(state, payload) {
      state.listOfResources = payload;
    },
    setResourcesInContainer(state, payload) {
      state.resourcesInContainer = payload;
    },
    setSelectedResources(state, payload) {
      state.selectedResources = payload;
    },
    setSelectedResource(state, payload) {
      if (state.selectedResources && state.selectedResources.length) {
        const index = state.selectedResources.findIndex(
          item => item.id === payload.id
        );
        if (index !== -1) {
          state.selectedResources.splice(index, 1);
        } else {
          state.selectedResources.push(payload);
        }
      } else {
        state.selectedResources = [payload];
      }
    },
    setRolesList(state, payload) {
      state.listOfRoles = payload;
    },
    setRolesInContainer(state, payload) {
      state.rolesInContainer = payload;
    },
    setSelectedRoles(state, payload) {
      state.selectedRoles = payload;
    },
    setSelectedRole(state, payload) {
      if (state.selectedRoles && state.selectedRoles.length) {
        const index = state.selectedRoles.findIndex(
          item => item.id === payload.id
        );
        if (index !== -1) {
          state.selectedRoles.splice(index, 1);
        } else {
          state.selectedRoles.push(payload);
        }
      } else {
        state.selectedRoles = [payload];
      }
    },

    setReceptionDesksList(state, payload) {
      state.listOfReceptionDesks = payload;
    },
    setReceptionDesksInContainer(state, payload) {
      state.receptionDesksInContainer = payload;
    },
    setSelectedReceptionDesks(state, payload) {
      state.selectedReceptionDesks = payload;
    },
    setSelectedReceptionDesk(state, payload) {
      if (state.selectedReceptionDesks && state.selectedReceptionDesks.length) {
        const index = state.selectedReceptionDesks.findIndex(
          item => item.id === payload.id
        );
        if (index !== -1) {
          state.selectedReceptionDesks.splice(index, 1);
        } else {
          state.selectedReceptionDesks.push(payload);
        }
      } else {
        state.selectedReceptionDesks = [payload];
      }
    }
  },
  actions: {
    getContainerAccessTypes({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.containerAccessTypes}/all`)
        .then(response => {
          commit("setContainerAccessTypes", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getContainers({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "containers/getContainers",
        whereToSave: "containers/setContainers",
        url: apiEndpoints.company.containers,
        data: state.containers,
        params: params
      });
    },
    setContainers({ commit }, payload) {
      commit("setContainers", payload);
    },
    setSelectedContainer({ commit }, payload) {
      commit("setSelectedContainer", payload);
    },
    deleteContainer({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.containers}/${id}`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedContainerAccessType({ commit }, payload) {
      commit("setSelectedContainerAccessType", payload);
    },
    getUsersList({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "containers/getUsersList",
        whereToSave: "containers/setUsersList",
        url: apiEndpoints.company.users,
        data: state.listOfUsers,
        params: {}
      });
    },
    setUsersList({ commit }, payload) {
      commit("setUsersList", payload);
    },
    getUsersInContainer({ commit }, containerId) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.containers}/${containerId}/users/all`)
        .then(response => {
          commit("setUsersInContainer", response.data);
          const { data } = response.data;

          if (data && data.length) {
            commit("setSelectedUsers", [...data]);
          } else {
            commit("setSelectedUsers", null);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getUserManagersInContainer({ commit }, containerId) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(
          `${apiEndpoints.company.containers}/${containerId}/usermanagers/all`
        )
        .then(response => {
          commit("setUsersInContainer", response.data);
          const { data } = response.data;

          if (data && data.length) {
            commit("setSelectedUsers", [...data]);
          } else {
            commit("setSelectedUsers", null);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedUser({ commit }, payload) {
      commit("setSelectedUser", payload);
    },
    addUsersToContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_id, user_ids } = payload;
      httpServiceAuth
        .post(`${apiEndpoints.company.containers}/${container_id}/users`, {
          user_ids
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteUsersFromContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_id, user_ids } = payload;
      httpServiceAuth
        .delete(`${apiEndpoints.company.containers}/${container_id}/users`, {
          data: {
            user_ids
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    addUserManagersToContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_id, user_ids } = payload;
      httpServiceAuth
        .post(
          `${apiEndpoints.company.containers}/${container_id}/usermanagers`,
          {
            user_ids
          }
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteUserManagersFromContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_id, user_ids } = payload;
      httpServiceAuth
        .delete(
          `${apiEndpoints.company.containers}/${container_id}/usermanagers`,
          {
            data: {
              user_ids
            }
          }
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateUsersInContainer({ state, dispatch }, container_id) {
      let containerId = container_id;
      let container_type;
      if (typeof container_id === "object") {
        containerId = container_id.container_id;
        container_type = container_id.container_type;
      }
      const selected = state.selectedUsers;
      const inContainer = state.usersInContainer && state.usersInContainer.data;
      const addToContainer =
        selected &&
        selected.length &&
        selected.filter(
          container =>
            inContainer.findIndex(item => item.id === container.id) === -1
        );
      const deleteFromContainer = inContainer.filter(
        container => selected.findIndex(item => item.id === container.id) === -1
      );

      if (deleteFromContainer && deleteFromContainer.length) {
        if (container_type && container_type === "USER_MANAGER") {
          dispatch("deleteUserManagersFromContainer", {
            container_id: containerId,
            user_ids: deleteFromContainer.map(item => item.id)
          });
        } else {
          dispatch("deleteUsersFromContainer", {
            container_id: containerId,
            user_ids: deleteFromContainer.map(item => item.id)
          });
        }
      }
      if (addToContainer && addToContainer.length) {
        if (container_type && container_type === "USER_MANAGER") {
          dispatch("addUserManagersToContainer", {
            container_id: containerId,
            user_ids: addToContainer.map(item => item.id)
          });
        } else {
          dispatch("addUsersToContainer", {
            container_id: containerId,
            user_ids: addToContainer.map(item => item.id)
          });
        }
      }
    },
    getLevelsList({ state }) {
      var params = {
        includes: ["all_parents"],
        depth: "ALL"
      };
      store.dispatch("pagination/paginate", {
        whatToCall: "containers/getLevelsList",
        whereToSave: "containers/setLevelsList",
        url: apiEndpoints.company.levels,
        data: state.listOfLevels,
        params: params
      });
    },
    setLevelsList({ commit }, payload) {
      commit("setLevelsList", payload);
    },
    getLevelsInContainer({ commit }, containerId) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.containers}/${containerId}/levels/all`)
        .then(response => {
          commit("setLevelsInContainer", response.data);
          const { data } = response.data;

          if (data && data.length) {
            commit("setSelectedLevels", [...data]);
          } else {
            commit("setSelectedLevels", null);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedLevel({ commit }, payload) {
      commit("setSelectedLevel", payload);
    },
    addLevelsToContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_id, level_ids } = payload;
      httpServiceAuth
        .post(`${apiEndpoints.company.containers}/${container_id}/levels`, {
          level_ids
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteLevelsFromContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_id, level_ids } = payload;
      httpServiceAuth
        .delete(`${apiEndpoints.company.containers}/${container_id}/levels`, {
          data: {
            level_ids
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateLevelsInContainer({ state, dispatch }, container_id) {
      const selected = state.selectedLevels;
      const inContainer =
        state.levelsInContainer && state.levelsInContainer.data;
      const addToContainer =
        selected &&
        selected.length &&
        selected.filter(
          container =>
            inContainer.findIndex(item => item.id === container.id) === -1
        );
      const deleteFromContainer = inContainer.filter(
        container => selected.findIndex(item => item.id === container.id) === -1
      );

      if (deleteFromContainer && deleteFromContainer.length) {
        dispatch("deleteLevelsFromContainer", {
          container_id,
          level_ids: deleteFromContainer.map(item => item.id)
        });
      }
      if (addToContainer && addToContainer.length) {
        dispatch("addLevelsToContainer", {
          container_id,
          level_ids: addToContainer.map(item => item.id)
        });
      }
    },
    getResourcesList({ commit }, params) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.resources}/all`, {
          params
        })
        .then(response => {
          commit("setResourcesList", response.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getResourcesInContainer({ commit }, containerId) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.containers}/${containerId}/resources/all`)
        .then(response => {
          commit("setResourcesInContainer", response.data);
          const { data } = response.data;

          if (data && data.length) {
            commit("setSelectedResources", [...data]);
          } else {
            commit("setSelectedResources", null);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedResource({ commit }, payload) {
      commit("setSelectedResource", payload);
    },
    addResourcesToContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_id, resource_ids } = payload;
      httpServiceAuth
        .post(`${apiEndpoints.company.containers}/${container_id}/resources`, {
          resource_ids
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteResourcesFromContainer({ commit }, payload) {
      commit("loader/setScreenLoading", true, { root: true });
      const { container_id, resource_ids } = payload;
      httpServiceAuth
        .delete(
          `${apiEndpoints.company.containers}/${container_id}/resources`,
          {
            data: {
              resource_ids
            }
          }
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateResourcesInContainer({ state, dispatch }, container_id) {
      const selected = state.selectedResources;
      const inContainer =
        state.resourcesInContainer && state.resourcesInContainer.data;

      // TODO add to container not working
      const addToContainer =
        selected &&
        selected.length &&
        selected.filter(
          container =>
            inContainer.findIndex(item => item.id === container.id) === -1
        );
      const deleteFromContainer =
        inContainer &&
        inContainer.filter(
          container =>
            selected.findIndex(item => item.id === container.id) === -1
        );

      if (deleteFromContainer && deleteFromContainer.length) {
        dispatch("deleteResourcesFromContainer", {
          container_id,
          resource_ids: deleteFromContainer.map(item => item.id)
        });
      }
      if (addToContainer && addToContainer.length) {
        dispatch("addResourcesToContainer", {
          container_id,
          resource_ids: addToContainer.map(item => item.id)
        });
      }
    },

    getRolesList({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "containers/getRolesList",
        whereToSave: "containers/setRolesList",
        url: apiEndpoints.company.roles,
        data: state.listOfRoles,
        params: params
      });
      // commit("loader/setScreenLoading", true, { root: true });
      // httpServiceAuth
      //   .get(apiEndpoints.company.roles, { params })
      //   .then(response => {
      //     commit("setRolesList", response.data);
      //   })
      //   .catch(error => {
      //     if (error.response) {
      //       errorHandler(error.response);
      //     }
      //   })
      //   .finally(() => {
      //     commit("loader/setScreenLoading", false, { root: true });
      //   });
    },
    getRolesInContainer({ commit }, containerId) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.containers}/${containerId}/roles/all`)
        .then(response => {
          commit("setRolesInContainer", response.data);
          const { data } = response.data;

          if (data && data.length) {
            commit("setSelectedRoles", [...data]);
          } else {
            commit("setSelectedRoles", null);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedRole({ commit }, payload) {
      commit("setSelectedRole", payload);
    },
    updateRolesInContainer({ state, commit }, container_id) {
      const selected = state.selectedRoles.map(item => item.id);
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(`${apiEndpoints.company.containers}/${container_id}/roles`, {
          role_ids: selected
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },

    getReceptionDesksList({ state }, params) {
      store.dispatch("pagination/paginate", {
        whatToCall: "containers/getReceptionDesksList",
        whereToSave: "containers/setReceptionDesksList",
        url: apiEndpoints.company.receptionDesks,
        data: state.listOfReceptionDesks,
        params: params
      });
    },
    setReceptionDesksList({ commit }, payload) {
      commit("setReceptionDesksList", payload);
    },
    getReceptionDesksInContainer({ commit }, containerId) {
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(
          `${apiEndpoints.company.containers}/${containerId}/receptiondesks/all`
        )
        .then(response => {
          commit("setReceptionDesksInContainer", response.data);
          const { data } = response.data;

          if (data && data.length) {
            commit("setSelectedReceptionDesks", [...data]);
          } else {
            commit("setSelectedReceptionDesks", null);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedReceptionDesk({ commit }, payload) {
      commit("setSelectedReceptionDesk", payload);
    },
    updateReceptionDesksInContainer({ state, commit }, container_id) {
      const selected = state.selectedReceptionDesks.map(item => item.id);
      commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(
          `${apiEndpoints.company.containers}/${container_id}/receptiondesks`,
          {
            reception_desk_ids: selected
          }
        )
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
