<template>
  <button @click="setMenuStatus(true)" class="open-main-menu icon-hea1">
    <icon icon="#cx-hea1-menu" />
  </button>
  <button
    v-if="isPreferenceMenuModule"
    @click="setShowMenu()"
    class="toggle-main-menu icon-hea1"
  >
    <icon icon="#cx-hea1-menu" />
  </button>
</template>

<script>
import { mapActions } from "vuex";
import { preferenceMenuModules } from "@/services/consts/modules/preference-menu-modules";

export default {
  name: "HomeButton",
  computed: {
    isPreferenceMenuModule() {
      return preferenceMenuModules.find(
        el => el.pathName === this.$route.matched[0].name
      );
    }
  },
  methods: {
    ...mapActions("global", ["setMenuStatus", "setShowMenu"])
  }
};
</script>
