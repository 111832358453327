import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    receptions: null,
    selectedReception: null,
    reception: null,
    receptionsTranslations: [],
    levels: null,
    receptionLevel: null,
    selectedReceptionParent: null,
    visitorTemplate: null,
    containers: null,
    allowedContainers: null,
    receptionContainer: null,
    accessTypeId: null,
    workingTimeStart: null,
    workingTimeEnd: null,
    parents: null
  },
  mutations: {
    setParents(state, payload) {
      state.parents = payload;
    },
    setReceptions(state, payload) {
      state.receptions = payload;
    },
    setSelectedReception(state, payload) {
      state.selectedReception = payload;
    },
    setReception(state, payload) {
      state.reception = payload;
    },
    setReceptionTranslations(state, payload) {
      state.receptionsTranslations = payload;
    },
    setLevels(state, payload) {
      state.levels = payload;
    },
    setReceptionLevel(state, payload) {
      state.receptionLevel = payload;
    },
    setReceptionParent(state, payload) {
      state.receptionParent = payload;
    },
    setVisitorTemplate(state, payload) {
      state.visitorTemplate = payload;
    },
    setContainers(state, payload) {
      state.containers = payload;
    },
    setAllowedContainers(state, payload) {
      state.allowedContainers = payload;
    },
    setReceptionContainer(state, payload) {
      state.receptionContainer = payload;
    },
    setAccessTypeId(state, payload) {
      state.accessTypeId = payload;
    },
    setWorkingTimeStart(state, payload) {
      state.workingTimeStart = payload;
    },
    setWorkingTimeEnd(state, payload) {
      state.workingTimeEnd = payload;
    }
  },
  actions: {
    /**
     * We dont allowe nested parent-child so we need list of receptions that may be parent(reception where parent_id === null)
     */
    getParents({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.receptionDesks}`;
      return httpServiceAuth
        .get(url, {
          params: {
            parents: true,
            exclude_id: id
          }
        })
        .then(response => {
          commit("setParents", response.data.data);
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Get all receptions
     */
    getReceptions({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "reception/getReceptions",
        whereToSave: "reception/setReceptions",
        url: apiEndpoints.company.receptionDesks,
        data: state.receptions,
        params: {}
      });
    },
    setReceptions({ commit }, payload) {
      commit("setReceptions", payload);
    },
    setSelectedReception({ commit }, payload) {
      commit("setSelectedReception", payload);
    },
    /**
     * Delete Reception
     */
    deleteReception({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.receptionDesks}/${id}`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Get single reception
     */
    getReception({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      commit("setReceptionTranslations", []);
      const url = `${apiEndpoints.company.receptionDesks}/${id}`;
      return httpServiceAuth
        .get(url, {
          params: {
            includes: ["translations"]
          }
        })
        .then(response => {
          commit("setReception", response.data.data);
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Get levels
     */
    getLevels({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.levels}/all`;
      const params = {
        includes: ["all_parents"]
      };
      return httpServiceAuth
        .get(url, { params })
        .then(response => {
          commit("setLevels", response.data.data);
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Get Containers
     */
    getContainerAccessTypes({ commit }) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.containerAccessTypes}/all`)
        .then(response => {
          let accessTypes = response.data.data;
          const accessType = accessTypes.find(a => a.type == "USER");
          commit("setAccessTypeId", accessType.id);
          return accessType.id;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Get all containers
     */
    getContainers({ commit, state }) {
      commit("loader/setScreenLoading", true, { root: true });
      const params = { access_type_ids: [state.accessTypeId] };
      return httpServiceAuth
        .get(`${apiEndpoints.company.containers}/all`, { params })
        .then(response => {
          commit("setContainers", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Get allowed reception containers
     */
    getAllowedContainers({ commit, state }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const params = { access_type_ids: [state.accessTypeId] };
      return httpServiceAuth
        .get(`${apiEndpoints.company.allowedContainers}/${id}`, { params })
        .then(response => {
          commit("setAllowedContainers", response.data.data);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setAllowedContainer({ commit, state }, id) {
      let copy = [];
      if (
        state.allowedContainers &&
        typeof state.allowedContainers !== undefined
      ) {
        copy = [...state.allowedContainers];
      }
      let res = copy.findIndex(element => element.id === id);
      if (res > -1) {
        if (state.receptionContainer !== null) {
          if (copy[res].id === state.receptionContainer.id) {
            commit("setReceptionContainer", null);
          }
        }
        copy.splice(res, 1);
      } else {
        copy.push(state.containers.find(el => el.id === id));
      }
      commit("setAllowedContainers", copy);
    },
    unsetAllowedContainers({ commit }) {
      commit("setAllowedContainers", null);
    },
    /**
     * Update reception
     */
    updateReception({ commit, dispatch }, { data, id }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.receptionDesks}/${id}`;

      return httpServiceAuth
        .put(url, data)
        .then(response => {
          commit("setReception", response.data.data);
          dispatch("getReceptions");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Create reception
     */
    createReception({ commit, dispatch }, values) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.receptionDesks}`;
      return httpServiceAuth
        .post(url, values)
        .then(() => {
          commit("pagination/setPaginationData", null, { root: true });
          dispatch("getReceptions");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    /**
     * Set selected level
     */
    setReceptionLevel({ commit }, payload) {
      commit("setReceptionLevel", payload);
    },
    /**
     * Set selected container
     */
    setReceptionContainer({ commit }, payload) {
      commit("setReceptionContainer", payload);
    },
    /**
     * Set selected parent(add and edit page)
     */
    setReceptionParent({ commit }, payload) {
      commit("setReceptionParent", payload);
    },
    /**
     * Find visitor template from selected reception
     */
    setVisitorTemplate({ commit }, template) {
      commit("setVisitorTemplate", template);
    },
    setReception({ commit }, reception) {
      commit("setReception", reception);
    }
  }
};
