import axios from "axios";
import qs from "qs";
import decode from "jwt-decode";
import { apiEndpoints } from "@/services/constants";
import router from "@/router";

const envVariables = process.env;
let subdomain = getSubdomain();

const { VUE_APP_BASE_URL } = envVariables;

const headers = {
  Accept: "application/json, text/javascript, */*; q=0.01",
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0"
};

if (subdomain) {
  headers.Subdomain = subdomain;
}

const httpServiceAuth = axios.create({
  baseURL: VUE_APP_BASE_URL,
  withCredentials: false,
  headers
});

// helper used only if we nest http service auth and another axios call
const axiosHelper = axios.create({
  baseURL: VUE_APP_BASE_URL,
  withCredentials: false,
  headers
});

httpServiceAuth.defaults.paramsSerializer = params => {
  return qs.stringify(params);
};

httpServiceAuth.interceptors.request.use(
  config => {
    return new Promise(resolve => {
      if (!config.url.includes("lang=")) {
        if (!config.params) {
          config.params = {
            lang: getLang()
          };
        } else {
          config.params = {
            ...config.params,
            lang: getLang()
          };
        }
      }

      let accessToken = getAccessToken();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      resolve(config);
    });
  },
  error => {
    clearAccessToken();
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
httpServiceAuth.interceptors.response.use(
  response => {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      let isRefreshing = false;
      let pendingRequests = [];
      if (isRefreshing) {
        pendingRequests.push(originalRequest);
      } else {
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          isRefreshing = true;
          refreshToken()
            .then(response => {
              const access_token =
                response && response.data && response.data.access_token;

              if (access_token) {
                saveAccessToken(access_token);
                if (originalRequest.config && originalRequest.config.headers) {
                  originalRequest.config.headers.Authorization = `Bearer ${access_token}`;
                } else {
                  originalRequest.config = {
                    ...originalRequest.config,
                    headers: {
                      Authorization: `Bearer ${access_token}`
                    }
                  };
                }

                return Promise.resolve(httpServiceAuth(originalRequest));
              } else {
                clearAccessToken();
                router.go({ name: "r_login" });
                pendingRequests = [];
                return Promise.reject(error);
              }
            })
            .catch(error => {
              clearAccessToken();
              router.go({ name: "r_login" });
              pendingRequests = [];
              return Promise.reject(error);
            })
            .finally(() => {
              isRefreshing = false;
            });
        } else {
          if (pendingRequests.length) {
            pendingRequests.forEach(req => {
              return Promise.resolve(httpServiceAuth(req));
            });
          }
        }
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export function getSubdomain() {
  let host = window.location.hostname.replace("www.", "").split(".");
  let subdomain;
  let removeItemNum = 2;

  // check if we're on development env which is a subdomain by default
  // if so remove last three elements instead of last two elements
  if (
    host.indexOf("clebex-ui-dev") !== -1 ||
    host.indexOf("qa") !== -1 ||
    host.indexOf("staging") !== -1 ||
    host.indexOf("api") !== -1 ||
    host.indexOf("preprod") !== -1
  )
    removeItemNum = 3;

  if (host.length > removeItemNum) {
    host.splice(-removeItemNum, removeItemNum); // remove last two/three elements
    subdomain = host.join(".");
  }

  // TODO company mock

  return subdomain;
}

export const getAccessToken = () => {
  return localStorage.getItem("atApp");
};

export const saveAccessToken = token => {
  localStorage.setItem("atApp", token);
};

export const clearAccessToken = () => {
  // console.trace();
  localStorage.clear();
};

export const getUserId = () => {
  const token = getAccessToken();
  if (token) {
    return decode(token).sub;
  }
  return null;
};

export const isLoggedIn = () => {
  let accessToken = getAccessToken();
  return !!accessToken && !isTokenExpired(accessToken);
};

export const getTokenExpirationDate = encodedToken => {
  let token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  let date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
};

export const isTokenExpired = token => {
  let expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
};

export const refreshToken = async () => {
  const { company, master } = apiEndpoints;
  const url = getSubdomain() ? company.refresh : master.refresh;
  const accessToken = getAccessToken();
  if (!accessToken) {
    return new Promise(reject => reject());
  }

  return axiosHelper.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

export const getLang = () => {
  const langInStorage = localStorage.getItem("appLang");
  if (langInStorage) {
    return langInStorage;
  }
  return document.documentElement.lang;
};

export const setLang = lang => {
  localStorage.setItem("appLang", lang);
  document.documentElement.lang = lang;
};

export const setLangId = id => {
  localStorage.setItem("appUserLangId", id);
};

export const getSamlResponse = () => {
  const search = window.location.search;
  const clearQuestionMark = search.replace("?", "");
  const queryArray = clearQuestionMark.split("&");
  let SAMLResponse;
  for (let i = 0; i < queryArray.length; i++) {
    const name = queryArray[i].split("=")[0];
    if (name.toLowerCase() === "samlresponse") {
      SAMLResponse = queryArray[i].split("=")[1];
      break;
    }
  }
  return SAMLResponse;
};

export const getSsoErrorMessage = () => {
  const search = window.location.search;
  const clearQuestionMark = search.replace("?", "");
  const queryArray = clearQuestionMark.split("&");
  let errorMessage;
  for (let i = 0; i < queryArray.length; i++) {
    const name = queryArray[i].split("=")[0];
    if (name.toLowerCase() === "message") {
      errorMessage = queryArray[i].split("=")[1];
      break;
    }
  }
  return errorMessage;
};

export default httpServiceAuth;
