import { APPLICATIONS } from "@/services/consts/application";

const BOOKINGS_MIDDLEWARES = {
  auth: null,
  permission: ["BOOKINGS, MY_BOOKINGS"],
  document: { applicationId: APPLICATIONS.APP_BOOKINGS.id },
  menuPreferences: "BOOKINGS"
};

export default {
  path: "/bookings",
  name: "r_bookings",
  meta: {
    middlewares: BOOKINGS_MIDDLEWARES
  },
  component: () => import("../../components/bookings/Bookings"),
  children: [
    {
      path: "presets",
      name: "r_bookings-search-presets",
      meta: {
        middlewares: BOOKINGS_MIDDLEWARES
      },
      props: {
        backLinkName: "r_bookings"
      },
      component: () => import("../../components/search/SearchPresets"),
      children: [
        {
          path: "add",
          name: "r_bookings-search-preset-add",
          meta: {
            middlewares: BOOKINGS_MIDDLEWARES
          },
          props: {
            backLinkName: "r_bookings-search-presets"
          },
          component: () => import("../../components/search/SearchPreset")
        },
        {
          path: ":id",
          name: "r_bookings-search-preset-edit",
          meta: {
            middlewares: BOOKINGS_MIDDLEWARES
          },
          props: {
            backLinkName: "r_bookings-search-presets"
          },
          component: () => import("../../components/search/SearchPreset")
        }
      ]
    },
    {
      path: ":id",
      name: "r_bookings-declaration",
      meta: {
        middlewares: BOOKINGS_MIDDLEWARES
      },
      component: () => import("../../components/bookings/Declaration"),
      children: [
        {
          path: "select-slots",
          name: "r_bookings-declaration-edit-select-slots",
          meta: {
            middlewares: BOOKINGS_MIDDLEWARES
          },
          props: {
            backLinkName: "r_bookings-declaration"
          },
          component: () => import("../../components/bookings/SelectSlots")
        }
      ]
    },
    {
      path: "create",
      name: "r_bookings-declaration-create",
      meta: {
        middlewares: BOOKINGS_MIDDLEWARES
      },
      component: () => import("../../components/bookings/DeclarationCreate"),
      children: [
        {
          path: "select-slots",
          name: "r_bookings-declaration-create-select-slots",
          meta: {
            middlewares: BOOKINGS_MIDDLEWARES
          },
          props: {
            backLinkName: "r_bookings-declaration-create"
          },
          component: () => import("../../components/bookings/SelectSlots")
        }
      ]
    }
  ]
};
